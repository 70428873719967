import { useEffect, useReducer } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnboardingDialogWrapper } from "./onboarding-dialog-wrapper";
import { yupResolver } from "@hookform/resolvers/yup";
import { PlanStep } from "src/components/molecules/onboarding/plan-step";
import { NumberOfUsersStep } from "src/components/molecules/onboarding/number-of-users-step";
import { BuildingStep } from "src/components/molecules/onboarding/building-step";
import { AccessControlIntegrationStep } from "src/components/molecules/onboarding/access-control-integration-step";
import { NumberOfSpotsStep } from "src/components/molecules/onboarding/number-of-spots-step";
import { SuccessStep } from "src/components/molecules/onboarding/success-step";
import {
  initialState,
  reducer,
  OnboardingFormValues,
  FormTriggerKeys,
  renderOnboardingSteps,
  PlanOptions,
} from "./utils";
import * as Yup from "yup";
import { restoreSettings, useSettings } from "src/contexts/settings-context";
import { OrganizationRegistrationType } from "src/types/enum/organization-registration-type";
import { useCompleteOrganizationRegistration } from "src/hooks/api/organization/use-complete-registration";
import { useCompleteSiteRegistration } from "src/features/site/hooks/api/sites/use-complete-registration";
import {
  OrganizationOnboardingPayload,
  SiteOnboardingPayload,
} from "src/types/payloads/onboarding-payload";
import { adminsProfileKeys } from "src/api/query-keys/admins-profile";
import { useQueryClient } from "@tanstack/react-query";
import { CreateSitePayload } from "src/features/site/types/payloads/sites/create-site-payload";
import { useCreateSite } from "src/features/site/hooks/api/sites/use-create-site";
import { siteAdminsProfileKeys } from "src/features/site/api/query-keys/site-admins-profile";
import { wait } from "src/utils/wait";
import { ThemeProvider } from "@material-ui/core";
import { createCustomTheme } from "src/theme";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  role: OrganizationRegistrationType;
  canCancelOnboardingProcess?: boolean;
}

const defaultValues: OnboardingFormValues = {
  plan: "free",
  numberOfSpots: null,
  numberOfUsers: null,
  building: {
    siteName: "",
    streetName: "",
    buildingNumber: "",
    cityName: "",
    postcode: "",
  },
  accessControlIntegration: "no",
  phoneNumber: "",
};

const MAX_NUMBER_OF_SPOTS = 100000000000000;
const MAX_NUMBER_OF_USERS = 100000000000000;
const MAX_CHARACTERS = 255;

export const OnboardingDialog = ({ isOpen, onClose, role, canCancelOnboardingProcess }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { settings, saveSettings } = useSettings();

  const [state, dispatch] = useReducer(reducer, initialState);

  const validationSchema = Yup.object({
    plan: Yup.mixed<PlanOptions>()
      .oneOf(["free", "premium"])
      .required(t("onboarding:validation.planRequired")),
    accessControlIntegration: Yup.mixed().oneOf(["no", "yes"]),
    numberOfSpots: Yup.number().when("accessControlIntegration", {
      is: "yes",
      then: Yup.number()
        .typeError(t("onboarding:spots.numberValidation"))
        .min(0)
        .max(
          MAX_NUMBER_OF_SPOTS,
          t("validation:maxNumber", {
            number: MAX_NUMBER_OF_SPOTS,
          }),
        )
        .positive(t("onboarding:spots.positiveNumberValidation"))
        .required(),
      otherwise: Yup.number().nullable(),
    }),
    numberOfUsers: Yup.number().when("plan", {
      is: "premium",
      then: Yup.number()
        .typeError(t("onboarding:users.numberValidation"))
        .min(0)
        .max(
          MAX_NUMBER_OF_USERS,
          t("validation:maxNumber", {
            number: MAX_NUMBER_OF_USERS,
          }),
        )
        .positive(t("onboarding:users.positiveNumberValidation"))
        .required(),
      otherwise: Yup.number().nullable(),
    }),
    building: Yup.object({
      siteName:
        role !== OrganizationRegistrationType.TENANT_INVITED
          ? Yup.string()
              .typeError(t("onboarding:validation.required"))
              .max(
                MAX_CHARACTERS,
                t("validation:maxCharacters", {
                  count: MAX_CHARACTERS,
                }),
              )
              .required(t("onboarding:validation.required"))
          : Yup.string().max(
              MAX_CHARACTERS,
              t("validation:maxCharacters", {
                count: MAX_CHARACTERS,
              }),
            ),
      streetName: Yup.string().max(
        MAX_CHARACTERS,
        t("validation:maxCharacters", {
          count: MAX_CHARACTERS,
        }),
      ),
      buildingNumber: Yup.string().max(
        MAX_CHARACTERS,
        t("validation:maxCharacters", {
          count: MAX_CHARACTERS,
        }),
      ),
      cityName: Yup.string().max(
        MAX_CHARACTERS,
        t("validation:maxCharacters", {
          count: MAX_CHARACTERS,
        }),
      ),
      postcode: Yup.string().max(
        MAX_CHARACTERS,
        t("validation:maxCharacters", {
          count: MAX_CHARACTERS,
        }),
      ),
    }),
    phoneNumber: Yup.string().when("accessControlIntegration", {
      is: "yes",
      then: Yup.string()
        .typeError(t("onboarding:spots.numberValidation"))
        .required(t("onboarding:validation.phoneNumberRequired"))
        .matches(/^[+0-9]+$/, t("onboarding:validation.phoneNumber")),
      otherwise: Yup.string(),
    }),
  });

  const form = useForm<OnboardingFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { trigger } = form;

  const onCancel = async () => {
    onClose();
    const restoredSettings = restoreSettings();
    saveSettings(restoredSettings);
    await wait(1000);
    dispatch({ type: "RESET" });
    form.reset(defaultValues);
  };

  const onNextStep = async (values?: FormTriggerKeys[]) => {
    if (state.currentStep >= state.steps.length) return;

    if (values) {
      const result = await form.trigger(values);
      if (!result) return;
    }

    dispatch({ type: "NEXT_STEP" });
  };

  const integration = form.watch("accessControlIntegration");
  const plan = form.watch("plan");

  const {
    mutateAsync: completeOrganizationRegistration,
    isLoading: isLoadingCompleteOrganizationRegistration,
  } = useCompleteOrganizationRegistration();

  const { mutateAsync: createSite, isLoading: isLoadingCreateSite } = useCreateSite();

  const { mutateAsync: completeSiteRegistration, isLoading: isLoadingCompleteSiteRegistration } =
    useCompleteSiteRegistration();

  useEffect(() => {
    const steps = renderOnboardingSteps(role, plan, integration);
    dispatch({ type: "SET_STEPS", payload: steps });
  }, [integration, role, plan]);

  const onPreviousStep = () => {
    if (state.currentStep <= 0) return;
    dispatch({ type: "PREVIOUS_STEP" });
  };

  const onSubmit = async (data: OnboardingFormValues) => {
    const result = await trigger("building");

    if (!result) return;
    const sitePlan = data?.numberOfSpots > 0 ? "premium" : "free";

    Object.keys(data.building).forEach(buildingKey => {
      if (data.building[buildingKey] === "") {
        data.building[buildingKey] = null;
        return;
      }
    });

    if (canCancelOnboardingProcess) {
      const registrationData: CreateSitePayload = {
        ...data.building,
        numberOfSpots: data?.numberOfSpots,
        name: data?.building?.siteName,
        phoneNumber: data?.phoneNumber,
        sitePlan,
      };
      await createSite(registrationData);
      dispatch({ type: "NEXT_STEP" });
      return;
    }

    if (role === OrganizationRegistrationType.SITE_OWNER) {
      const registrationData: SiteOnboardingPayload = {
        ...data.building,
        numberOfSpots: data?.numberOfSpots,
        numberOfUsers: data?.numberOfUsers,
        phoneNumber: data?.phoneNumber,
        sitePlan,
      };
      await completeSiteRegistration(registrationData);
      dispatch({ type: "NEXT_STEP" });
      return;
    }
    const registrationData: OrganizationOnboardingPayload = {
      ...data.building,
      numberOfSpots: data?.numberOfSpots,
      numberOfUsers: data?.numberOfUsers,
      phoneNumber: data?.phoneNumber,
      sitePlan,
      organizationPlan: data?.plan,
    };
    await completeOrganizationRegistration(registrationData);
    dispatch({ type: "NEXT_STEP" });
  };

  const onStart = () => {
    queryClient.invalidateQueries({ queryKey: adminsProfileKeys.all });
    queryClient.invalidateQueries({ queryKey: siteAdminsProfileKeys.all });
    onCancel();
  };

  const friendlyCurrentStep = state.currentStep + 1;
  const totalSteps = state.steps?.length - 1;

  const renderStep = () => {
    switch (state.steps[state.currentStep]) {
      case "plan":
        return (
          <PlanStep
            currentStep={friendlyCurrentStep}
            isFormSubmitting={
              isLoadingCompleteOrganizationRegistration ||
              isLoadingCompleteSiteRegistration ||
              isLoadingCreateSite
            }
            onNextStep={role === "tenantInvited" && plan === "free" ? undefined : onNextStep}
            onSubmit={role === "tenantInvited" && plan === "free" ? onSubmit : undefined}
            stepsLength={totalSteps}
          />
        );
      case "numberOfUsers":
        return (
          <NumberOfUsersStep
            onNextStep={role === "tenantInvited" ? undefined : () => onNextStep(["numberOfUsers"])}
            currentStep={friendlyCurrentStep}
            onPreviousStep={onPreviousStep}
            stepsLength={totalSteps}
            onSubmit={role === "tenantInvited" ? onSubmit : undefined}
            isFormSubmitting={
              isLoadingCompleteOrganizationRegistration ||
              isLoadingCompleteSiteRegistration ||
              isLoadingCreateSite
            }
          />
        );
      case "building":
        return (
          <BuildingStep
            currentStep={friendlyCurrentStep}
            onPreviousStep={onPreviousStep}
            stepsLength={totalSteps}
            onSubmit={onSubmit}
            isFormSubmitting={
              isLoadingCompleteOrganizationRegistration ||
              isLoadingCompleteSiteRegistration ||
              isLoadingCreateSite
            }
          />
        );
      case "accessControlIntegration":
        return (
          <AccessControlIntegrationStep
            onNextStep={() => onNextStep(["accessControlIntegration", "phoneNumber"])}
            onPreviousStep={
              role !== OrganizationRegistrationType.SITE_OWNER ? () => onPreviousStep() : undefined
            }
            stepsLength={totalSteps}
            currentStep={friendlyCurrentStep}
          />
        );
      case "numberOfSpots":
        return (
          <NumberOfSpotsStep
            onNextStep={() => onNextStep(["numberOfSpots"])}
            currentStep={friendlyCurrentStep}
            onPreviousStep={onPreviousStep}
            stepsLength={totalSteps}
          />
        );
      case "success":
        return <SuccessStep onStart={onStart} />;
      default:
        return null;
    }
  };

  //? Here we are setting the theme only for the onboarding dialog in light theme to prevent flickering when switching from dark theme when we are using a single theme provider
  const onboardingDialogTheme = createCustomTheme({
    direction: settings.direction,
    theme: "light",
  });

  return (
    <ThemeProvider theme={onboardingDialogTheme}>
      <OnboardingDialogWrapper
        isOpen={isOpen}
        onClose={onCancel}
        canCancelOnboardingProcess={canCancelOnboardingProcess}>
        <FormProvider {...form}>{renderStep()}</FormProvider>
      </OnboardingDialogWrapper>
    </ThemeProvider>
  );
};
