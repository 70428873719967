import { useEffect, useMemo, useState } from "react";
import type { FC } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Box, Divider, Drawer, List, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Scrollbar } from "../atoms/scrollbar";
import { SidebarItem } from "../molecules/sidebar/sidebar-item";
import { Route } from "src/types/interfaces/route";
import { SiteSelector } from "./site-selector";
import { ViewContext } from "src/store/user-store";
import { OrganizationSelector } from "./organization-selector";
import useStore from "src/hooks/use-store";
import { getRoutes as getSiteRoutes } from "src/features/site/constants/routes";
import { useAdminsProfile } from "src/hooks/api/organization/use-admins-profile";
import { useSiteAdminsProfile } from "src/features/site/hooks/api/admins/use-site-admins-profile";
import { getRoutes } from "src/features/tenant/constants/routes";

interface SidebarProps {
  onPin: () => void;
  pinned: boolean;
}

const workAreaSelector = (viewContext: ViewContext) => {
  switch (viewContext) {
    case "site":
      return <SiteSelector />;

    case "tenant":
      return <OrganizationSelector />;

    default:
      return null;
  }
};

const getRoutesForViewContext = (currentViewContext: ViewContext, isLegacyAgreement: boolean) => {
  switch (currentViewContext) {
    case "site":
      return getSiteRoutes(isLegacyAgreement);

    case "tenant":
      return getRoutes(isLegacyAgreement);
  }
};

const SidebarComponent: FC<SidebarProps> = props => {
  const { t } = useTranslation();
  const { pinned } = props;
  const { pathname } = useLocation();
  const { user } = useStore();
  const { data } = useAdminsProfile();

  const { data: siteAdminProfile } = useSiteAdminsProfile();

  const sections = useMemo(
    () =>
      getRoutesForViewContext(
        user.viewContext,
        user.viewContext === "site"
          ? siteAdminProfile?.actualSite?.isLegacyAgreement
          : data?.actualOrganization?.isLegacyAgreement,
      ),
    [
      user.viewContext,
      data?.actualOrganization?.isLegacyAgreement,
      siteAdminProfile?.actualSite?.isLegacyAgreement,
    ],
  );
  const routes = useMemo(() => sections.flatMap(route => route.routes), [sections]);

  const [activeRoute, setActiveRoute] = useState<Route | null>(null);

  useEffect(() => {
    routes.forEach(route => {
      const active = matchPath({ path: route.href, end: false }, pathname);

      if (active) {
        setActiveRoute(route);
      }
    });
  }, [pathname, routes]);

  return (
    <>
      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px)",
            overflowX: "hidden",
            top: 64,
            zIndex: 1,
            transition: "width 250ms ease-in-out",
            width: pinned ? 270 : 73,
            "& .simplebar-content": {
              height: "100%",
            },
            "&:hover": {
              width: 270,
              "& span, p": {
                display: "flex",
              },
            },
          },
        }}>
        <Scrollbar
          style={{
            display: "flex",
            flex: 1,
            overflowX: "hidden",
            overflowY: "auto",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              p: 2,
              pt: 2.5,
              pb: 7,
            }}>
            <Box
              sx={{
                mb: 3,
              }}>
              {workAreaSelector(user.viewContext)}
            </Box>

            <List disablePadding>
              {sections.map((section, index) => (
                <Box key={section.title}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      mb: 1,
                    }}
                    color="text.secondary">
                    {t(section.title)}
                  </Typography>
                  {section.routes.map(route => (
                    <Box key={route.id}>
                      <SidebarItem
                        key={route.title}
                        href={route.href}
                        active={activeRoute?.title === route.title}
                        external={route.external}
                        title={route.title}
                        pinned={pinned}
                        id={route?.id}
                        {...route}
                      />
                    </Box>
                  ))}
                  {index !== sections.length - 1 && <Divider sx={{ mt: 2, mb: 1.4 }} />}
                </Box>
              ))}
            </List>
          </Box>
        </Scrollbar>
      </Drawer>
    </>
  );
};

export const Sidebar = observer(SidebarComponent);
