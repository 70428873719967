import { useAdminsProfile } from "src/hooks/api/organization/use-admins-profile";
import { useSiteAdminsProfile } from "src/features/site/hooks/api/admins/use-site-admins-profile";
import { useEffect, useState } from "react";
import useStore from "./use-store";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth-provider";
import { authRoutes } from "src/constants/auth-routes";

type ReturnType = { tenantContext: boolean; siteContext: boolean };

export const useSetViewContext = (): ReturnType => {
  const { user } = useStore();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [access, setAccess] = useState({
    tenantContext: false,
    siteContext: false,
  });

  const [hasFetchedAccess, setHasFetchedAccess] = useState(false);

  const tenantAdminsProfile = useAdminsProfile(
    !!auth.session?.access_token && !authRoutes.includes(location.pathname),
    false,
  );

  useEffect(() => {
    if (tenantAdminsProfile.isSuccess) {
      setAccess(prev => ({
        ...prev,
        tenantContext: true,
      }));
    }

    if (tenantAdminsProfile.isError) {
      setAccess(prev => ({
        ...prev,
        tenantContext: false,
      }));
    }
  }, [tenantAdminsProfile.isSuccess, tenantAdminsProfile.isError]);

  const siteAdminsProfile = useSiteAdminsProfile(
    !!auth.session?.access_token && !authRoutes.includes(location.pathname),
    false,
  );

  useEffect(() => {
    if (siteAdminsProfile.isSuccess) {
      setAccess(prev => ({
        ...prev,
        siteContext: true,
      }));
    }

    if (siteAdminsProfile.isError) {
      setAccess(prev => ({
        ...prev,
        siteContext: false,
      }));
    }
  }, [siteAdminsProfile.isSuccess, siteAdminsProfile.isError]);

  useEffect(() => {
    if (
      (tenantAdminsProfile.isSuccess || tenantAdminsProfile.isError) &&
      (siteAdminsProfile.isSuccess || siteAdminsProfile.isError)
    ) {
      setHasFetchedAccess(true);
    }
  }, [
    siteAdminsProfile.isError,
    siteAdminsProfile.isSuccess,
    tenantAdminsProfile.isError,
    tenantAdminsProfile.isSuccess,
  ]);

  useEffect(() => {
    if (hasFetchedAccess) {
      switch (true) {
        case access.siteContext && !access.tenantContext && user.viewContext !== "site":
          user.setViewContext("site");
          user.setIsLoadingViewContextAccess(false);

          navigate("/");

          break;

        case !access.siteContext && access.tenantContext && user.viewContext !== "tenant":
          user.setViewContext("tenant");
          user.setIsLoadingViewContextAccess(false);

          navigate("/");

          break;

        default:
          user.setIsLoadingViewContextAccess(false);
          break;
      }
    }
  }, [access.siteContext, access.tenantContext, hasFetchedAccess, user]);

  return access;
};
