import siteApi from "../clients/site-api";
import { SiteAdmin } from "src/features/site/types/responses/site-admin";
import { UserRole } from "src/types/enum/user-role";
import { PaginatedData } from "src/types/responses/paginated-data";
import { SiteAdminProfile } from "../../types/responses/site-admin-profile";

class SiteAdminsApi {
  async fetchSiteAdmins(page: number, perPage: number): Promise<PaginatedData<SiteAdmin>> {
    const { data } = await siteApi.get<PaginatedData<SiteAdmin>>("/admins", {
      params: {
        page,
        perPage,
      },
    });
    return data;
  }

  async fetchSiteAdmin(id: number): Promise<SiteAdmin> {
    const { data } = await siteApi.get<SiteAdmin>(`/admins/${id}`);

    return data;
  }

  async createSiteAdmin(email: string, role: UserRole, phoneNumber: string): Promise<SiteAdmin> {
    const { data } = await siteApi.post<SiteAdmin>("/admins", {
      email,
      role,
      phoneNumber,
    });

    return data;
  }

  async updateSiteAdminPhoneNumber(id: number, phoneNumber: string): Promise<SiteAdmin> {
    const { data } = await siteApi.patch<SiteAdmin>(`/admins/${id}`, {
      phoneNumber,
    });

    return data;
  }

  async updateSiteAdminRole(id: number, role: UserRole): Promise<SiteAdmin> {
    const { data } = await siteApi.patch<SiteAdmin>(`/admins/${id}/role`, {
      role,
    });

    return data;
  }

  async removeSiteAdmin(id: number): Promise<void> {
    await siteApi.delete<SiteAdmin>(`/admins/${id}`);
  }

  async fetchSiteAdminsProfile(): Promise<SiteAdminProfile> {
    const { data } = await siteApi.get<SiteAdminProfile>(`/admins/profile`);

    return data;
  }

  async resendSiteAdminInvitation(siteAdminId: number): Promise<void> {
    await siteApi.post(`/admins/${siteAdminId}/resend-invitation`);
  }
}

export const siteAdminsApi = new SiteAdminsApi();
