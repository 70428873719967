import { OrganizationContact } from "src/types/responses/organization-contact";
import api from "../clients/api";
import { Site, Address } from "src/types/responses/site";
import { OrganizationInfo } from "src/types/responses/organization-info";
import { OrganizationOnboardingPayload } from "src/types/payloads/onboarding-payload";
import { IntegrationQuestionnairePayload } from "src/features/site/types/payloads/settings/integration-questionnaire";
import { SiteIntegrationQuestionnaireState } from "src/features/site/types/enum/site-integration-questionnaire-state";
import { IntegrationQuestionnaireQuestion } from "src/features/site/types/responses/integration-questionnaire-question";
import { IntegrationQuestionnaire } from "src/features/site/types/responses/integration-questionnaire";
import { RemoveOrganization } from "src/types/responses/remove-organization";
import { RemoveOrganizationStatus } from "src/types/responses/remove-organization-status";
import { ApproveOrganizationRemoval } from "src/types/responses/approve-organization-removal";

class OrganizationDetailsApi {
  async fetchOrganization(): Promise<OrganizationInfo> {
    const { data } = await api.get(`/organization`);
    return data;
  }

  async fetchOrganizationContacts(): Promise<OrganizationContact[]> {
    const { data } = await api.get<OrganizationContact[]>("/contact-details");

    return data;
  }

  async createContact(
    firstName: string,
    lastName: string,
    position: string,
    email: string,
    phoneNumber: string,
  ): Promise<OrganizationContact> {
    const { data } = await api.post<OrganizationContact>("/contact-details", {
      firstName,
      lastName,
      position,
      email,
      phoneNumber: phoneNumber?.toString(),
    });

    return data;
  }

  async updateContact(
    contactId: string,
    firstName: string,
    lastName: string,
    position: string,
    email: string,
    phoneNumber: string,
  ): Promise<OrganizationContact> {
    const { data } = await api.patch<OrganizationContact>(`/contact-details/${contactId}`, {
      firstName,
      lastName,
      position,
      email,
      phoneNumber,
    });
    return data;
  }

  async removeOrganizationContact(id: string): Promise<void> {
    await api.delete(`/contact-details/${id}`);
  }

  async fetchOrganizationSites(): Promise<Site[]> {
    const { data } = await api.get<Site[]>("/sites");

    return data;
  }

  async createSite(name: string, address: Address): Promise<Site> {
    const { data } = await api.post<Site>("/sites", {
      name,
      ...address,
    });

    return data;
  }

  async editSite(id: number, name: string, address: Address): Promise<Site> {
    const { data } = await api.patch<Site>(`sites/${id}`, {
      name,
      ...address,
    });
    return data;
  }

  async uploadIntegrationQuestionnaireImages(
    siteId: string,
    images: FormData,
  ): Promise<{ url: string }[]> {
    const { data } = await api.post<{ url: string }[]>(
      `/sites/${siteId}/integration-questionnaire/photos`,
      images,
    );

    return data;
  }

  async fetchIntegrationQuestionnaire(id: number): Promise<IntegrationQuestionnaire> {
    const { data } = await api.get<IntegrationQuestionnaire>(
      `/sites/${id}/integration-questionnaire`,
    );
    return data;
  }

  async fetchIntegrationQuestionnaireQuestions(): Promise<IntegrationQuestionnaireQuestion[]> {
    const { data } = await api.get<IntegrationQuestionnaireQuestion[]>(
      `/sites/integration-questionnaire/questions`,
    );

    return data;
  }

  async sendIntegrationQuestionnaire(
    id: number,
    data: {
      questionnaire: IntegrationQuestionnairePayload[];
      photo1?: string;
      photo2?: string;
      state: SiteIntegrationQuestionnaireState;
    },
  ): Promise<void> {
    await api.post<void>(`/sites/${id}/integration-questionnaire`, data);
  }

  async completeRegistration(values: OrganizationOnboardingPayload): Promise<void> {
    await api.post(`/organization/complete-registration`, values);
  }

  async removeOrganization(organizationName: string): Promise<RemoveOrganization> {
    const { data } = await api.post<RemoveOrganization>(`/organization/remove`, {
      organizationName,
    });

    return data;
  }

  async fetchRemoveOrganizationStatus(): Promise<RemoveOrganizationStatus> {
    const { data } = await api.get<RemoveOrganizationStatus>(`/organization/remove/status`);

    return data;
  }

  async approveRemoveOrganization(): Promise<ApproveOrganizationRemoval> {
    const { data } = await api.post<ApproveOrganizationRemoval>(`/organization/remove/approve`);

    return data;
  }

  async rejectRemoveOrganization(): Promise<void> {
    await api.delete<void>(`/organization/remove/reject`);
  }
}

export const organizationDetailsApi = new OrganizationDetailsApi();
