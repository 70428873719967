import type { FC } from "react";
import { format as dateFormat } from "date-fns";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

interface Props {
  date: Date;
  format: string;
}

export const FormatDate: FC<Props> = ({ date, format }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === "pl" ? pl : en;

  return <>{dateFormat(date, format, { locale: locale })}</>;
};

export const formatDate = (date: Date, format: string) => {
  const locale = i18n.language === "pl" ? pl : en;

  return dateFormat(date, format, { locale: locale });
};
