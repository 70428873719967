import { AbilityBuilder } from "@casl/ability";
import { AppAbility, Role } from "./define-permissions";

export const tenantContextPermissions = (
  builder: AbilityBuilder<AppAbility>,
  role: Role,
  userId: number,
  shouldHaveAccessToSiteActionsInTenantContext: boolean,
  hasAccessControlIntegration: boolean,
  isLegacyAgreement: boolean,
) => {
  const { can, cannot } = builder;

  can("read", "Reservations");

  can("read", "BookingRules");

  can("read", "ParkingSpots");

  can("read", "ResourceGroups");

  can("read", "PermanentSpots");

  can("read", "SpareSpots");

  can("read", "OrganizationDetails");

  can("read", "ResourceGroupsResources");

  can("read", "Employees");

  can("read", "EmployeeInvitations");

  can("read", "EmployeeReservations");

  can("read", "EmployeeStatistics");

  can("read", "EmployeeGroups");
  can("readLatestBadgeStatusHistory", "EmployeeGroups");
  can("downloadActiveSessions", "EmployeeGroups");

  can("read", "EmployeeGroupEmployees");

  can("read", "EmployeeGroupResourceGroups");

  can("read", "EmployeeGroupBookingRules");

  can("read", "EmployeeGroupReservations");

  can("read", "Statistics");

  can("read", "Issues");

  can("read", "BlackListedLicensePlate");

  can("read", "TenantAdministrators");

  can("read", "Sites");

  can("read", "GdprClause");

  can("read", "OrganizationRemoval");

  can("read", "SharedSpots");

  if (role === "admin" || role === "owner" || role === "master") {
    if (shouldHaveAccessToSiteActionsInTenantContext) {
      can("create", "ParkingSpots");
      can("update", "ParkingSpots");
      can("delete", "ParkingSpots");

      can("update", "IntegrationQuestionnaire");
    }

    can("create", "Reservations");
    can("finish", "Reservations");
    can("cancel", "Reservations");

    can("create", "BookingRules");
    can("update", "BookingRules");
    can("delete", "BookingRules");

    can("cancel", "SharedSpots");

    can("assign", "ParkingSpots");
    can("detach", "ParkingSpots");
    can("addMap", "ParkingSpots");
    can("updatePriority", "ParkingSpots");
    can("setAsReserve", "ParkingSpots");
    can("deleteMap", "ParkingSpots");
    can("lockSpot", "ParkingSpots");
    can("unlockSpot", "ParkingSpots");

    can("share", "PermanentSpots");
    can("createPermanentBooking", "PermanentSpots");
    can("update", "PermanentSpots");
    can("cancel", "PermanentSpots");

    can("create", "ResourceGroups");
    can("update", "ResourceGroups");
    can("delete", "ResourceGroups");

    can("delete", "ResourceGroupsResources");
    can("assignResource", "ResourceGroupsResources");

    can("create", "Employees");
    can("delete", "Employees");
    can("downloadXls", "Employees");
    can("ban", "Employees");
    can("changeGroup", "Employees");
    can("resendInvitation", "Employees");
    can("changeLicensePlate", "Employees");

    can("create", "EmployeeInvitations");
    can("update", "EmployeeInvitations");
    can("delete", "EmployeeInvitations");

    can("create", "EmployeeGroups");
    can("delete", "EmployeeGroups");
    can("changeBookingRules", "EmployeeGroups");
    can("setAsDefault", "EmployeeGroups");
    can("removeAsDefault", "EmployeeGroups");

    can("add", "EmployeeGroupEmployees");
    can("remove", "EmployeeGroupEmployees");

    can("add", "EmployeeGroupResourceGroups");
    can("remove", "EmployeeGroupResourceGroups");

    can("update", "EmployeeGroupBookingRules");

    can("update", "Issues");

    can("create", "BlackListedLicensePlate");
    can("delete", "BlackListedLicensePlate");

    can("updatePhoneNumber", "TenantAdministrators", { userId: { $eq: userId } });
    can("resendInvitation", "TenantAdministrators", { userId: { $ne: userId } });

    can("create", "GdprClause");
    can("update", "GdprClause");
    can("delete", "GdprClause");
  }

  if (role === "owner" || role === "master") {
    if (shouldHaveAccessToSiteActionsInTenantContext) {
      can("integrateWithAccessControlOn", "subscriptionPlan");

      can("create", "Sites");
      can("update", "Sites");
    }

    can("cancel", "OrganizationRemoval");
    can("acceptOrReject", "OrganizationRemoval");
    can("delete", "OrganizationRemoval");

    if (hasAccessControlIntegration) {
      can("changeSpotsLimit", "BillingPlan");
    }

    can("create", "TenantAdministrators");
    can("update", "TenantAdministrators");
    can("chooseRole", "TenantAdministrators");
    can("updatePhoneNumber", "TenantAdministrators");
    can("updateRole", "TenantAdministrators", { userId: { $ne: userId } });
    can("delete", "TenantAdministrators", { userId: { $ne: userId } });

    can("read", "SiteInvitations");
    can("acceptInvitation", "SiteInvitations");
    can("rejectInvitation", "SiteInvitations");

    can("read", "Billing");
    can("changePlan", "Billing");

    can("read", "BillingHistory");
    can("downloadInvoice", "BillingHistory");

    can("read", "BillingPlan");
    can("requestIntegration", "BillingPlan");

    can("read", "BillingDetails");
    can("update", "BillingDetails");
  }

  if (isLegacyAgreement) {
    cannot("read", "BillingPlan");
    cannot("read", "Billing");
    cannot("changePlan", "Billing");
    cannot("changeSpotsLimit", "BillingPlan");
    cannot("read", "BillingHistory");
    cannot("downloadInvoice", "BillingHistory");

    cannot("requestIntegration", "BillingPlan");

    cannot("read", "BillingDetails");
    cannot("update", "BillingDetails");
  }
};
