import { Booker } from "../../types/responses/booker";
import { Group } from "../../types/responses/group";
import api from "../clients/api";
import { PaginatedData } from "../../types/responses/paginated-data";
import { Reservation } from "../../types/responses/reservation";
import { AutomaticResourceAssignmentStrategyEnum } from "src/types/enum/automatic-resource-assignment-strategy";
import { SlotResolutionMilliseconds } from "src/types/enum/slot-resolution-milliseconds";

class GroupsApi {
  async fetchGroups(perPage: number, page: number): Promise<PaginatedData<Group>> {
    const { data } = await api.get<PaginatedData<Group>>(`/resource-groups`, {
      params: {
        perPage,
        page,
      },
    });

    return data;
  }

  async fetchSingleGroup(resourceGroupId: string): Promise<Group> {
    const { data } = await api.get<Group>(`/resource-groups/${resourceGroupId}/resources`);

    return data;
  }

  async addGroup(
    resourceType: string,
    name: string,
    maxSlotsAheadForReservation: number,
    slotResolutionMilliseconds: SlotResolutionMilliseconds,
    employeeMaxActiveSessions: number,
    minReservationSlots: number,
    maxReservationSlots: number,
    automaticResourceAssignmentStrategy: string,
    beforeSessionOffsetInMilliseconds: number,
    afterSessionOffsetInMilliseconds: number,
    isEmployeeBadgeRequiredForReservation: boolean,
    requiredUpfrontResourceSelection: boolean,
    requiredPresenceToConfirmResource: boolean,
    requiredAdditionalResourceConfirmationAfterReturn: boolean,
    canUseSpareResources: boolean,
    usesAccessControlIntegration: boolean,
    siteId: number,
  ): Promise<Group> {
    const { data } = await api.post<Group>(`/resource-groups`, {
      resourceType,
      name,
      maxSlotsAheadForReservation,
      slotResolutionMilliseconds,
      employeeMaxActiveSessions,
      minReservationSlots,
      maxReservationSlots,
      automaticResourceAssignmentStrategy,
      beforeSessionOffsetInMilliseconds,
      afterSessionOffsetInMilliseconds,
      isEmployeeBadgeRequiredForReservation,
      requiredUpfrontResourceSelection,
      requiredPresenceToConfirmResource,
      requiredAdditionalResourceConfirmationAfterReturn,
      canUseSpareResources,
      usesAccessControlIntegration,
      siteId,
    });

    return data;
  }

  async updateGroup(
    resourceGroupId: number,
    maxSlotsAheadForReservation: number,
    minReservationSlots: number,
    maxReservationSlots: number,
    slotResolutionMilliseconds: SlotResolutionMilliseconds,
    employeeMaxActiveSessions: number,
    automaticResourceAssignmentStrategy: AutomaticResourceAssignmentStrategyEnum,
    beforeSessionOffsetInMilliseconds: number,
    afterSessionOffsetInMilliseconds: number,
    isEmployeeBadgeRequiredForReservation: boolean,
    requiredUpfrontResourceSelection: boolean,
    requiredPresenceToConfirmResource: boolean,
    requiredAdditionalResourceConfirmationAfterReturn: boolean,
    canUseSpareResources: boolean,
    usesAccessControlIntegration: boolean,
  ): Promise<Group> {
    const { data } = await api.patch<Group>(`/resource-groups/${resourceGroupId}`, {
      maxSlotsAheadForReservation,
      minReservationSlots,
      maxReservationSlots,
      slotResolutionMilliseconds,
      employeeMaxActiveSessions,
      automaticResourceAssignmentStrategy,
      beforeSessionOffsetInMilliseconds,
      afterSessionOffsetInMilliseconds,
      isEmployeeBadgeRequiredForReservation,
      requiredUpfrontResourceSelection,
      requiredPresenceToConfirmResource,
      requiredAdditionalResourceConfirmationAfterReturn,
      canUseSpareResources,
      usesAccessControlIntegration,
    });

    return data;
  }

  async removeGroup(resourceGroupId: number): Promise<Group> {
    const { data } = await api.delete<Group>(`/resource-groups/${resourceGroupId}`);

    return data;
  }

  async addEmployeeToGroup(resourceGroupId: number, employeeIdentifier: number): Promise<Booker> {
    const { data } = await api.post<Booker>(`/resource-groups/${resourceGroupId}/employees`, {
      employeeIdentifier,
    });

    return data;
  }

  async assignEmployeesToGroup(resourceGroupId: number, employeeIds: number[]): Promise<Booker[]> {
    const { data } = await api.put<Booker[]>(`/resource-groups/${resourceGroupId}/employees`, {
      employeeIds,
    });

    return data;
  }

  async removeEmployeeFromGroup(resourceGroupId: number, employeeId: number): Promise<Booker> {
    const { data } = await api.delete<Booker>(
      `/resource-groups/${resourceGroupId}/employees/${employeeId}`,
    );

    return data;
  }

  async fetchGroupReservations(
    resourceGroupId: number,
    perPage: number,
    page: number,
  ): Promise<PaginatedData<Reservation>> {
    const { data } = await api.get<PaginatedData<Reservation>>(
      `/resource-groups/${resourceGroupId}/sessions`,
      {
        params: {
          perPage,
          page,
        },
      },
    );

    return data;
  }
}

export const groupsApi = new GroupsApi();
