import api from "../clients/api";
import { PaginatedData } from "../../types/responses/paginated-data";
import { OrganizationAdmin } from "src/types/responses/organization-admin";
import { AdminProfile } from "src/types/responses/admin-profile";
import { UserRole } from "src/types/enum/user-role";

class OrganizationAdminsApi {
  async fetchOrganizationAdminsList(
    page: number,
    perPage: number,
  ): Promise<PaginatedData<OrganizationAdmin>> {
    const { data } = await api.get<PaginatedData<OrganizationAdmin>>(`/admins`, {
      params: {
        page,
        perPage,
      },
    });

    return data;
  }

  async removeAdminFromOrganization(adminId: number): Promise<void> {
    const { data } = await api.delete(`/admins/${adminId}`);

    return data;
  }

  async inviteOrganizationAdmin(
    email: string,
    phoneNumber: string,
    role: UserRole,
  ): Promise<OrganizationAdmin> {
    const { data } = await api.post<OrganizationAdmin>(`/admins`, {
      email,
      phoneNumber,
      role,
    });

    return data;
  }

  async resendOrganizationAdminInvitation(adminId: number): Promise<void> {
    await api.post(`/admins/${adminId}/resend-invitation`);
  }

  async updateOrganizationAdminPhoneNumber(
    id: number,
    phoneNumber: string,
  ): Promise<OrganizationAdmin> {
    const { data } = await api.patch<OrganizationAdmin>(`/admins/${id}`, {
      phoneNumber,
    });

    return data;
  }

  async updateOrganizationAdminRole(id: number, role: UserRole): Promise<OrganizationAdmin> {
    const { data } = await api.patch<OrganizationAdmin>(`/admins/${id}/role`, {
      role,
    });

    return data;
  }

  async fetchAdminProfile(): Promise<AdminProfile> {
    const { data } = await api.get<AdminProfile>(`/admins/profile`);

    return data;
  }
}

export const organizationAdminsApi = new OrganizationAdminsApi();
