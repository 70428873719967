import { Suspense, lazy } from "react";
import type { DataRouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { AuthGuard } from "src/components/guards/auth-guard";
import { LoadingScreen } from "src/components/loading-screen";
import { Layout } from "src/layout/main-layout";

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

//Visit page
const Reservations = Loadable(
  lazy(() =>
    import("../../containers/reservations").then(module => ({
      default: module.ReservationsContainer,
    })),
  ),
);

const Reservation = Loadable(
  lazy(() =>
    import("../../containers/reservations/[reservationId]").then(module => ({
      default: module.ReservationContainer,
    })),
  ),
);

const CreateReservation = Loadable(
  lazy(() =>
    import("../../containers/reservations/create").then(module => ({
      default: module.CreateReservationContainer,
    })),
  ),
);

const EmployeesContainer = Loadable(
  lazy(() =>
    import("../../containers/employees").then(module => ({ default: module.EmployeesContainer })),
  ),
);
const Employees = Loadable(
  lazy(() =>
    import("../../components/templates/employees/employees").then(module => ({
      default: module.Employees,
    })),
  ),
);

const EmployeesGroups = Loadable(
  lazy(() =>
    import("../../components/templates/employees/employees-groups").then(module => ({
      default: module.default,
    })),
  ),
);

const Employee = Loadable(
  lazy(() =>
    import("../../containers/employees/[employeeId]").then(module => ({
      default: module.EmployeeDetailsContainer,
    })),
  ),
);

const EmployeeStatistics = Loadable(
  lazy(() =>
    import("../../containers/employees/employee-statistics").then(module => ({
      default: module.EmployeeStatisticsContainer,
    })),
  ),
);

const EmployeeReservations = Loadable(
  lazy(() =>
    import("../../containers/employees/employee-reservations").then(module => ({
      default: module.EmployeeReservationsContainer,
    })),
  ),
);

const Resource = Loadable(
  lazy(() =>
    import("../../containers/resources/index").then(module => ({
      default: module.ResourcesContainer,
    })),
  ),
);

const Resources = Loadable(
  lazy(() =>
    import("../../containers/resources/spots").then(module => ({
      default: module.SpotsContainer,
    })),
  ),
);

const ResourceGroups = Loadable(
  lazy(() =>
    import("../../containers/resources/resource-groups").then(module => ({
      default: module.ResourceGroupsContainer,
    })),
  ),
);

const PermanentSpots = Loadable(
  lazy(() =>
    import("../../containers/resources/permanent-spots").then(module => ({
      default: module.PermanentSpotsContainer,
    })),
  ),
);

const SpareSpots = Loadable(
  lazy(() =>
    import("../../containers/resources/spare-spots").then(module => ({
      default: module.SpareSpotsContainer,
    })),
  ),
);

const EmployeesGroup = Loadable(
  lazy(() =>
    import("../../containers/employees/[groupId]").then(module => ({
      default: module.EmployeesGroupContainer,
    })),
  ),
);

const ResourceGroupResources = Loadable(
  lazy(() =>
    import("../../containers/resources/resource-group-resources").then(module => ({
      default: module.ResourceGroupResourcesContainer,
    })),
  ),
);

const RuleGroups = Loadable(
  lazy(() =>
    import("../../containers/rule-groups").then(module => ({
      default: module.RuleGroupsContainer,
    })),
  ),
);

const CreateNewRules = Loadable(
  lazy(() =>
    import("../../containers/rule-groups/create-rule-group").then(module => ({
      default: module.CreateRuleGroupContainer,
    })),
  ),
);

const RuleGroupDetails = Loadable(
  lazy(() =>
    import("../../containers/rule-groups/rule-group-details").then(module => ({
      default: module.RuleGroupDetailsContainer,
    })),
  ),
);

const Statistics = Loadable(
  lazy(() =>
    import("../../containers/statistics").then(module => ({
      default: module.StatisticsContainer,
    })),
  ),
);

const OrganizationDetails = Loadable(
  lazy(() =>
    import("../../containers/organization").then(module => ({
      default: module.OrganizationDetailsContainer,
    })),
  ),
);

const OrganizationRemoveStatus = Loadable(
  lazy(() =>
    import("../../containers/organization/remove-organization-status").then(module => ({
      default: module.RemoveOrganizationStatusContainer,
    })),
  ),
);

const SitesDetails = Loadable(
  lazy(() =>
    import("../../containers/sites-details").then(module => ({
      default: module.SitesDetailsContainer,
    })),
  ),
);

const SiteInvitations = Loadable(
  lazy(() =>
    import("../../containers/sites-details/site-invitations").then(module => ({
      default: module.InvitationsContainer,
    })),
  ),
);

const Sites = Loadable(
  lazy(() =>
    import("../../containers/sites-details/sites").then(module => ({
      default: module.SitesContainer,
    })),
  ),
);

const Contact = Loadable(
  lazy(() =>
    import("../../containers/settings/contact").then(module => ({
      default: module.ContactContainer,
    })),
  ),
);

const ChangePassword = Loadable(
  lazy(() =>
    import("../../containers/settings/change-password").then(module => ({
      default: module.ChangePasswordContainer,
    })),
  ),
);

const DiagramPreview = Loadable(
  lazy(() =>
    import("../../containers/diagram").then(module => ({
      default: module.DiagramPreviewContainer,
    })),
  ),
);

const Issues = Loadable(
  lazy(() =>
    import("../../containers/issues").then(module => ({
      default: module.IssuesContainer,
    })),
  ),
);
const Issue = Loadable(
  lazy(() =>
    import("../../containers/issues/[issueId]").then(module => ({
      default: module.IssueContainer,
    })),
  ),
);

const Settings = Loadable(
  lazy(() =>
    import("../../containers/settings").then(module => ({
      default: module.SettingsContainer,
    })),
  ),
);
const Gdpr = Loadable(
  lazy(() =>
    import("../../containers/settings/gdpr").then(module => ({
      default: module.GdprContainer,
    })),
  ),
);

//? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
// const Billing = Loadable(
//   lazy(() =>
//     import("../../containers/billing").then(module => ({
//       default: module.BillingContainer,
//     })),
//   ),
// );

// const YourPlan = Loadable(
//   lazy(() =>
//     import("../../containers/billing/your-plan").then(module => ({
//       default: module.YourPlanContainer,
//     })),
//   ),
// );

// const BillingAvailablePlans = Loadable(
//   lazy(() =>
//     import("../../containers/billing/billing-available-plans").then(module => ({
//       default: module.BillingAvailablePlansContainer,
//     })),
//   ),
// );

// const BillingDetails = Loadable(
//   lazy(() =>
//     import("../../containers/billing/billing-details").then(module => ({
//       default: module.BillingDetailsContainer,
//     })),
//   ),
// );

// const BillingHistory = Loadable(
//   lazy(() =>
//     import("../../containers/billing/billing-history").then(module => ({
//       default: module.BillingHistoryContainer,
//     })),
//   ),
// );

const IntegrationQuestionnaireSitesContainer = Loadable(
  lazy(() =>
    import("../../containers/settings/integration-questionnaire").then(module => ({
      default: module.IntegrationQuestionnaireSitesContainer,
    })),
  ),
);

const IntegrationQuestionnaireFormContainer = Loadable(
  lazy(() =>
    import(
      "../../containers/settings/integration-questionnaire/integration-questionnaire-form"
    ).then(module => ({
      default: module.IntegrationQuestionnaireFormContainer,
    })),
  ),
);

const SiteIntegrationQuestionnaire = Loadable(
  lazy(() =>
    import(
      "../../containers/settings/integration-questionnaire/[siteIdIntegrationQuestionnaire]"
    ).then(module => ({
      default: module.SiteIntegrationQuestionnaire,
    })),
  ),
);

const routes: DataRouteObject[] = [
  {
    path: "/",
    id: "statistics-container",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "/statistics",
        path: "/",
        element: <Navigate to="/statistics" replace />,
      },
    ],
  },
  {
    path: "/reservations",
    id: "reservations-container",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "reservations",
        index: true,
        element: <Reservations />,
      },
      {
        id: "reservation",
        path: "/reservations/:reservationId",
        element: <Reservation />,
      },
      {
        id: "createReservation",
        path: "/reservations/create",
        element: <CreateReservation />,
      },
    ],
  },
  {
    id: "employees-container",
    path: "employees",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "employees",
        path: "/employees",
        element: <EmployeesContainer />,
        children: [
          {
            id: "employees",
            path: "/employees/",
            element: <Employees />,
          },
          {
            id: "employees-groups",
            path: "/employees/groups",
            element: <EmployeesGroups />,
          },
        ],
      },

      {
        id: "employee",
        path: "/employees/:employeeId",
        element: <Employee />,
        children: [
          {
            id: "employee-statistics",
            path: "/employees/:employeeId",
            element: <EmployeeStatistics />,
            index: true,
          },
          {
            id: "employee-reservations",
            path: "/employees/:employeeId/reservations",
            element: <EmployeeReservations />,
          },
        ],
      },
      {
        id: "employees-group-details",
        path: "/employees/groups/:groupId",
        element: <EmployeesGroup />,
      },
    ],
  },
  {
    id: "resource-container",
    path: "resource",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "resource",
        path: "/resource",
        element: <Resource />,
        children: [
          {
            id: "resource-spots",
            path: "/resource/spots",
            element: <Resources />,
          },
          {
            id: "resource-spots-group",
            path: "/resource/groups",
            element: <ResourceGroups />,
          },
          {
            id: "resource-permanent-spots",
            path: "/resource/permanent-spots",
            element: <PermanentSpots />,
          },
          {
            id: "resource-spare-spots",
            path: "/resource/spare-spots",
            element: <SpareSpots />,
          },
        ],
      },
      {
        id: "resource-spots-group-resources",
        path: "/resource/spots-group/:locationId",
        element: <ResourceGroupResources />,
      },
    ],
  },
  {
    id: "statistics-container",
    path: "statistics",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "statistics",
        path: "/statistics",
        element: <Statistics />,
      },
    ],
  },
  {
    id: "booking-rules-container",
    path: "booking-rules",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "booking-rules",
        path: "/booking-rules",
        element: <RuleGroups />,
      },
      {
        id: "booking-rules-details",
        path: "/booking-rules/:ruleGroupId",
        element: <RuleGroupDetails />,
      },
      {
        id: "create-rules",
        path: "/booking-rules/create",
        element: <CreateNewRules />,
      },
    ],
  },
  {
    id: "sites-details-container",
    path: "sites-details",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "sites-details",
        path: "/sites-details",
        element: <SitesDetails />,
        children: [
          {
            id: "sites-details-sites",
            index: true,
            element: <Sites />,
          },
          {
            id: "site-invitation",
            path: "/sites-details/site-invitation",
            element: <SiteInvitations />,
          },
        ],
      },
    ],
  },
  {
    id: "organization-details-container",
    path: "organization-details",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "organization-details",
        path: "/organization-details",
        element: <OrganizationDetails />,
      },
      {
        id: "organization-remove-status",
        path: "/organization-details/remove/status",
        element: <OrganizationRemoveStatus />,
      },
    ],
  },
  {
    id: "diagram-container",
    path: "diagram",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "diagram-preview",
        path: "/diagram",
        element: <DiagramPreview />,
      },
    ],
  },
  {
    id: "issues-container",
    path: "issues",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "issues",
        path: "/issues",
        element: <Issues />,
      },
      {
        id: "issue",
        path: "/issues/:issueId",
        element: <Issue />,
      },
    ],
  },
  {
    id: "settings-container",
    path: "settings",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "settings",
        path: "/settings",
        element: <Settings />,
        children: [
          {
            id: "settings-gdpr",
            index: true,
            element: <Gdpr />,
          },
          {
            id: "settings-contact-form",
            path: "/settings/contact-form",
            element: <Contact />,
          },
          {
            id: "settings-change-password-form",
            path: "/settings/change-password",
            element: <ChangePassword />,
          },
          {
            id: "integration",
            path: "/settings/integration",
            element: <IntegrationQuestionnaireSitesContainer />,
          },
          {
            id: "integration-questionnaire",
            path: "/settings/integration-questionnaire",
            element: <IntegrationQuestionnaireFormContainer />,
          },
        ],
      },
      {
        id: "site-integration-questionnaire",
        path: "/settings/integration-questionnaire/:siteId",
        element: <SiteIntegrationQuestionnaire />,
      },
    ],
  },
  //? TODO: disable billing data fetching because Salesforce throws errors because it is not paid
  // {
  //   id: "billing-container",
  //   path: "billing",
  //   element: (
  //     <AuthGuard>
  //       <Layout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       id: "billing",
  //       path: "/billing",
  //       element: <Billing />,
  //       children: [
  //         {
  //           id: "billing-payments",
  //           index: true,
  //           element: <YourPlan />,
  //         },
  //         {
  //           id: "billing-available-plans",
  //           path: "/billing/available-plans",
  //           element: <BillingAvailablePlans />,
  //         },
  //         // {
  //         //   id: "billing-history",
  //         //   path: "/billing/history",
  //         //   element: <BillingHistory />,
  //         // },
  //         {
  //           id: "billing-details",
  //           path: "/billing/details",
  //           element: <BillingDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
