import { Box, Card, CardContent, Divider, FormHelperText, Typography } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PlanOptions, OnboardingFormValues } from "./utils";
import { lightNeutral, lightPrimary, lightText } from "src/colors";
import { CheckedIcon } from "src/icons/checked";
import { FEE_PER_SINGLE_USER } from "src/utils/calculate-monthly-fee";
import { formatPrice } from "src/utils/format-price";

export const OnboardingDialogPlan = () => {
  const { t, i18n } = useTranslation();

  const { control } = useFormContext<OnboardingFormValues>();

  const plans: {
    value: PlanOptions;
    title: string;
    price: string;
    description: string;
    functionalitiesTitle: string;
    functionalities: string[];
  }[] = [
    {
      value: "free",
      title: t("onboarding:plan.freePlan.title"),
      price: `${formatPrice({
        amount: 0,
        language: i18n.language,
      })}`,
      description: t("onboarding:plan.freePlan.description"),
      functionalitiesTitle: t("onboarding:plan.freePlan.functionalitiesTitle"),
      functionalities: [
        t("onboarding:plan.functionalities.analysis"),
        t("onboarding:plan.functionalities.establishingOwnRules"),
        t("onboarding:plan.functionalities.reportingIssues"),
        t("onboarding:plan.functionalities.modelsOfBooking"),
      ],
    },
    {
      value: "premium",
      title: t("onboarding:plan.proPlan.title"),
      price: `+${formatPrice({
        amount: FEE_PER_SINGLE_USER,
        language: i18n.language,
      })}`,
      description: t("onboarding:plan.proPlan.description"),
      functionalitiesTitle: t("onboarding:plan.proPlan.functionalitiesTitle"),
      functionalities: [
        t("onboarding:plan.functionalities.extentBookingRules"),
        t("onboarding:plan.functionalities.multiDayBookings"),
        t("onboarding:plan.functionalities.bookingSpotsByAdmin"),
        t("onboarding:plan.functionalities.sharingSpot"),
        t("onboarding:plan.functionalities.adminRoles"),
      ],
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: 1,
      }}>
      <Controller
        control={control}
        name="plan"
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <>
            {plans.map(plan => (
              <Card
                key={plan.value}
                variant="outlined"
                onClick={() => onChange(plan.value)}
                sx={{
                  boxShadow: plan.value === value ? lightPrimary.borders : lightNeutral[500],
                  background: plan.value === value ? lightNeutral[100] : lightNeutral[200],
                  width: "173px",
                  cursor: "pointer",
                  transition: "all 0.1s linear",
                }}>
                <CardContent sx={{ px: 1.875, py: 2.5 }}>
                  <Typography
                    color="black"
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                    }}>
                    {plan.title}
                  </Typography>
                  <Divider
                    sx={{
                      my: 1.5,
                      borderColor: plan.value === value ? lightNeutral[500] : "#E4F2F7",
                    }}
                  />
                  <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>{plan.price}</Typography>
                  <Typography color={lightText.secondary} sx={{ fontSize: "12px", pb: 2 }}>
                    {plan.description}
                  </Typography>
                  <Typography color={lightText.secondary} sx={{ fontSize: "12px", px: 0 }}>
                    {plan.functionalitiesTitle}
                  </Typography>
                  <Divider
                    sx={{
                      my: 1.5,
                      borderColor: plan.value === value ? lightNeutral[500] : "#E4F2F7",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.8,
                    }}>
                    {plan?.functionalities.map(functionality => (
                      <Box sx={{ display: "flex", gap: 0.8 }} key={functionality}>
                        <Box sx={{ display: "flex", alignSelf: "center" }}>
                          <CheckedIcon />
                        </Box>
                        <Typography sx={{ fontSize: "12px", lineHeight: "15px" }}>
                          {functionality}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}
            {error?.message ? <FormHelperText error>{error.message}</FormHelperText> : null}
          </>
        )}
      />
    </Box>
  );
};
