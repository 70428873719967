import common from "./common.json";
import reservations from "./reservations.json";
import reservation from "./reservation.json";
import createReservation from "./create-reservation.json";
import statistics from "./statistics.json";
import blacklist from "./blacklist.json";
import contactDetails from "./contact-details.json";
import contactForm from "./contact-form.json";
import createPermanentSession from "./create-permanent-session.json";
import createRuleGroup from "./create-rule-group.json";
import diagram from "./diagram.json";
import employee from "./employee.json";
import employees from "./employees.json";
import employeesGroupDetails from "./employees-group-details.json";
import employeesGroups from "./employees-groups.json";
import invitations from "./invitations.json";
import issues from "./issues.json";
import issueDetails from "./issue-details.json";
import organizationDetails from "./organization-details.json";
import organizationAdmins from "./organization-admins.json";
import resourceGroupDetails from "./resource-group-details.json";
import resources from "./resources.json";
import ruleGroups from "./rule-groups.json";
import sites from "./sites.json";
import validation from "./validation.json";
import settings from "./settings.json";
import changePassword from "./change-password.json";
import onboarding from "./onboarding.json";
import tenants from "./tenants.json";
import tenantDetails from "./tenant-details.json";
import siteManagement from "./site-management.json";
import billing from "./billing.json";
import routes from "./routes.json";
import auth from "./auth.json";

export default {
  common,
  reservations,
  reservation,
  createReservation,
  statistics,
  blacklist,
  contactDetails,
  contactForm,
  createPermanentSession,
  createRuleGroup,
  diagram,
  employee,
  employees,
  employeesGroupDetails,
  employeesGroups,
  invitations,
  issues,
  issueDetails,
  organizationDetails,
  resourceGroupDetails,
  resources,
  organizationAdmins,
  ruleGroups,
  sites,
  validation,
  settings,
  changePassword,
  onboarding,
  tenants,
  tenantDetails,
  siteManagement,
  routes,
  billing,
  auth,
};
