import { useState, useEffect, useMemo } from "react";
import type { FC } from "react";

import { matchPath, useLocation } from "react-router-dom";
import { Box, Drawer, List, Typography } from "@material-ui/core";
import { NavbarMenuItem } from "../molecules/navbar-menu/navbar-menu-item";

import { Route } from "src/types/interfaces/route";
import { getRoutes as getSiteRoutes } from "src/features/site/constants/routes";
import { useTranslation } from "react-i18next";
import { ViewContext } from "src/store/user-store";
import useStore from "src/hooks/use-store";
import { observer } from "mobx-react-lite";
import { useAdminsProfile } from "src/hooks/api/organization/use-admins-profile";
import { useSiteAdminsProfile } from "src/features/site/hooks/api/admins/use-site-admins-profile";
import { SiteSelector } from "./site-selector";
import { OrganizationSelector } from "./organization-selector";
import { getRoutes } from "src/features/tenant/constants/routes";

interface NavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

const getRoutesForViewContext = (currentViewContext: ViewContext, isLegacyAgreement: boolean) => {
  switch (currentViewContext) {
    case "site":
      return getSiteRoutes(isLegacyAgreement);

    case "tenant":
      return getRoutes(isLegacyAgreement);
  }
};

const workAreaSelector = (viewContext: ViewContext) => {
  switch (viewContext) {
    case "site":
      return <SiteSelector />;

    case "tenant":
      return <OrganizationSelector />;

    default:
      return null;
  }
};

const NavbarMenuComponent: FC<NavbarMenuProps> = props => {
  const { t } = useTranslation();

  const { open, onClose } = props;
  const { pathname } = useLocation();
  const { user } = useStore();

  const { data } = useAdminsProfile();

  const { data: siteAdminProfile } = useSiteAdminsProfile();

  const sections = useMemo(
    () =>
      getRoutesForViewContext(
        user.viewContext,
        user.viewContext === "site"
          ? siteAdminProfile?.actualSite?.isLegacyAgreement
          : data?.actualOrganization?.isLegacyAgreement,
      ),
    [
      user.viewContext,
      data?.actualOrganization?.isLegacyAgreement,
      siteAdminProfile?.actualSite?.isLegacyAgreement,
    ],
  );
  const routes = useMemo(() => sections.flatMap(route => route.routes), [sections]);

  const [activeRoute, setActiveRoute] = useState<Route | null>(null);

  useEffect(() => {
    routes.forEach(route => {
      const active = matchPath({ path: route.href, end: false }, pathname);

      if (active) {
        setActiveRoute(route);
      }
    });
  }, [pathname]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          color: "#B2B7C8",
          display: "flex",
          flexDirection: "column",
          top: 64,
          maxHeight: "calc(100% - 64px)",
          width: "100vw",
        },
      }}>
      <List>
        {workAreaSelector(user.viewContext)}
        {sections.map(section => (
          <Box key={section.title}>
            <Typography
              sx={{
                fontSize: "14px",
                my: 1,
                px: 1,
              }}
              color="#98a2b3">
              {t(section.title)}
            </Typography>
            {section.routes.map(route => (
              <NavbarMenuItem
                active={activeRoute?.title === route.title}
                key={route.title}
                href={route.href}
                onClose={onClose}
                {...route}
              />
            ))}
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export const NavbarMenu = observer(NavbarMenuComponent);
