import { Button, Menu, MenuItem } from "@material-ui/core";
import { usePopover } from "src/hooks/use-popover";
import { ChevronDown as ChevronDownIcon } from "src/icons/chevron-down";
import useStore from "src/hooks/use-store";
import { observer } from "mobx-react-lite";
import { useAdminsProfile } from "src/hooks/api/organization/use-admins-profile";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const OrganizationSelectorComponent = () => {
  const { user } = useStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const profileQuery = useAdminsProfile();

  const selectedOrganization = profileQuery.data?.organizations.find(
    organization => organization.token === user.userOrganizationId,
  );

  const handleOrganizationChange = (organizationToken: string) => {
    user.setIsChangingOrganization(true);
    user.setUserOrganizationId(organizationToken);

    handleClose();

    // window.location.reload();

    queryClient.invalidateQueries();

    navigate("/statistics");
  };

  return (
    <>
      <Button
        color="primary"
        // disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        variant="text"
        endIcon={<ChevronDownIcon fontSize="small" />}
        sx={{
          alignItems: "center",
        }}>
        {selectedOrganization?.name ?? "---"}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {profileQuery.data?.organizations.map(organization => {
          return (
            <MenuItem
              key={organization.id}
              onClick={() => {
                handleOrganizationChange(organization.token);
              }}>
              {organization.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export const OrganizationSelector = observer(OrganizationSelectorComponent);
