import { SiteAdminProfile } from "src/features/site/types/responses/site-admin-profile";
import { OrganizationRegistrationType } from "src/types/enum/organization-registration-type";
import { AdminProfile } from "src/types/responses/admin-profile";
import store from "src/store/application-store";

export type AvailableSteps =
  | "plan"
  | "accessControlIntegration"
  | "numberOfSpots"
  | "numberOfUsers"
  | "building"
  | "success";

export const determineIfOnboardingDialogShouldBeOpen = (
  organizationProfileData: AdminProfile,
  siteProfileData: SiteAdminProfile,
  enabled: boolean,
) => {
  if (!enabled) {
    return false;
  }
  if (
    siteProfileData?.actualSite?.isRegistrationComplete === false ||
    (organizationProfileData?.actualOrganization?.isRegistrationComplete === false &&
      store.user.viewContext === "tenant" &&
      !store.user.isChangingOrganization)
  ) {
    return true;
  }

  return false;
};

export const determineOnboardingContextByRegistrationType = (
  organizationProfileData: AdminProfile,
  siteProfileData: SiteAdminProfile,
): OrganizationRegistrationType => {
  if (siteProfileData?.actualSite?.isRegistrationComplete === false) {
    return OrganizationRegistrationType.SITE_OWNER;
  }
  if (
    organizationProfileData?.actualOrganization?.registrationType ===
    OrganizationRegistrationType.TENANT_OWNER
  ) {
    return OrganizationRegistrationType.TENANT_OWNER;
  }
  if (
    organizationProfileData?.actualOrganization?.registrationType ===
      OrganizationRegistrationType.TENANT_INVITED ||
    organizationProfileData?.actualOrganization?.registrationType ===
      OrganizationRegistrationType.SITE_OWNER
  ) {
    return OrganizationRegistrationType.TENANT_INVITED;
  }
  return;
};

export const renderOnboardingSteps = (
  role: OrganizationRegistrationType,
  plan: PlanOptions,
  integration: "no" | "yes",
): AvailableSteps[] => {
  switch (role) {
    case OrganizationRegistrationType.TENANT_OWNER:
      switch (plan === "free") {
        case true:
          if (integration === "yes") {
            return tenantFreePlanSteps;
          }
          return tenantFreePlanStepsWithoutIntegration;
        case false:
          if (integration === "yes") {
            return tenantSteps;
          }
          return tenantStepsWithoutIntegration;
      }
      break;
    case OrganizationRegistrationType.SITE_OWNER:
      switch (integration === "yes") {
        case true:
          return siteAdminSteps;
        case false:
          return siteAdminStepsWithoutIntegration;
      }
      break;
    case OrganizationRegistrationType.TENANT_INVITED:
      switch (plan === "free") {
        case true:
          return invitedTenantFreePlanSteps;
        case false:
          return invitedTenantSteps;
      }
  }
};

export const tenantSteps: AvailableSteps[] = [
  "plan",
  "numberOfUsers",
  "accessControlIntegration",
  "numberOfSpots",
  "building",
  "success",
];

export const invitedTenantSteps: AvailableSteps[] = ["plan", "numberOfUsers", "success"];

export const invitedTenantFreePlanSteps: AvailableSteps[] = ["plan", "success"];

export const tenantFreePlanStepsWithoutIntegration: AvailableSteps[] = [
  "plan",
  "accessControlIntegration",
  "building",
  "success",
];

export const tenantFreePlanSteps: AvailableSteps[] = [
  "plan",
  "accessControlIntegration",
  "numberOfSpots",
  "building",
  "success",
];

export const tenantStepsWithoutIntegration: AvailableSteps[] = [
  "plan",
  "numberOfUsers",
  "accessControlIntegration",
  "building",
  "success",
];

export const siteAdminSteps: AvailableSteps[] = [
  "accessControlIntegration",
  "numberOfSpots",
  "building",
  "success",
];

export const siteAdminStepsWithoutIntegration: AvailableSteps[] = [
  "accessControlIntegration",
  "building",
  "success",
];

type Action =
  | { type: "RESET" }
  | { type: "NEXT_STEP" }
  | { type: "PREVIOUS_STEP" }
  | { type: "SET_STEPS"; payload: AvailableSteps[] };

export interface OnboardingFormValues {
  plan: PlanOptions;
  numberOfSpots: number | null;
  numberOfUsers: number | null;
  building: {
    siteName: string;
    streetName: string;
    buildingNumber: string;
    cityName: string;
    postcode: string;
  };
  accessControlIntegration: "no" | "yes";
  phoneNumber: string;
}

export type FormTriggerKeys = keyof OnboardingFormValues;

export type PlanOptions = "free" | "premium";

export interface StepsState {
  currentStep: number;
  steps: AvailableSteps[];
}

export const initialState: StepsState = {
  currentStep: 0,
  steps: tenantSteps,
};

export const reducer = (state: StepsState, action: Action) => {
  switch (action.type) {
    case "RESET":
      return {
        ...state,
        currentStep: 0,
      };

    case "NEXT_STEP":
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case "PREVIOUS_STEP":
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case "SET_STEPS":
      return {
        ...state,
        steps: action.payload,
      };
    default:
      return state;
  }
};
