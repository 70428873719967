export enum RouteTitle {
  STATISTICS = "statistics:title",
  RESERVATIONS = "reservations:title",
  RULE_GROUPS = "ruleGroups:title",
  EMPLOYEES = "employees:title",
  TENANTS = "tenants:title",
  PARKING_SPOTS = `resources:resource.parkingSpots`,
  DESK = `resources:resource.desk`,
  SITES_DETAILS = "sites:siteDetails",
  ORGANIZATION_DETAILS = "organizationDetails:title",
  ORGANIZATION_ADMINS = "organizationAdmins:title",
  ISSUES = "issues:title",
  BLACKLIST = "blacklist:title",
  SETTINGS = "settings:title",
  BILLING = "billing:title",
  SITE_MANAGEMENT = "siteManagement:title",
}

export enum SidebarRouteTitle {
  PARKING_SETUP = "routes:parkingSetup",
  DATA_AND_ISSUE = "routes:dataAndIssues",
  SUPPORT_AND_SETTINGS = "routes:supportAndSettings",
}

export enum SubRouteTitle {}
