import { useEffect } from "react";
import useStore from "./use-store";
import { useAdminsProfile } from "./api/organization/use-admins-profile";
import { useSiteAdminsProfile } from "src/features/site/hooks/api/admins/use-site-admins-profile";

export const useSetInitialOrganizations = (enabled: boolean = true) => {
  const { user } = useStore();

  const organizationAdminQuery = useAdminsProfile(enabled);
  const siteAdminQuery = useSiteAdminsProfile(enabled);

  useEffect(() => {
    if (organizationAdminQuery.data?.organizations?.length > 0 && !user.userOrganizationId) {
      user.setUserOrganizationId(organizationAdminQuery.data?.organizations?.[0]?.token);
    }

    if (siteAdminQuery.data?.sites?.length > 0 && !user.siteToken) {
      user.setSiteToken(siteAdminQuery.data?.sites?.[0]?.token);
    }
  }, [organizationAdminQuery.data?.organizations, siteAdminQuery.data?.sites, user]);
};
